import Vue from 'vue';

export default {
    get(id) {
        return Vue.axios.get("grupos/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    update(parametros) {
        return Vue.axios.put("grupos", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    delete(id) {
        return Vue.axios.delete("grupos/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    store(parametros) {
        return Vue.axios.post("grupos", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    }
}