import gql from 'graphql-tag';

const gruposTableQuery = gql`
    query grupos($numberItems: Int!, $numberPage: Int!, $fieldOrder: String!, $Order: SortOrder!) {
        grupos(first: $numberItems, page: $numberPage, activo: true, orderBy:[{field: $fieldOrder, order: $Order}]) {
            data {
                id,
                nombre,
                descripcion,
                cliente_id,
                cliente{
                    nombre
                },
                grupoEmpresas{
                    id,
                    empresa_id
                }
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                lastItem
                hasMorePages
                lastPage
                perPage
                total
            }
        }
    }
`

const queries = { gruposTableQuery };

export default queries;