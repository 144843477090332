<template>
    <div id="pageTable">       
        <v-container grid-list-xl fluid>
            <v-row>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tabla"
                        :tableName="'Grupos'"
                        :columns="columns"
                        :items="tableData.data"
                        :paginationInfo="tableData.paginatorInfo"
                        @pagination="getPaginationInfo"
                        :showAdvancedFilters="false"
                        :perPage="[10,25,50,100]"
                        :loading="isLoading"
                    >
                        <template slot="actionButtonsLeft">
                            <div class="botonAgregar" >
                                <v-btn
                                rounded
                                color="#004BAF"
                                class="btnAdd"
                                right
                                @click="abrirModal('add')"
                                >
                                <v-icon dark>add</v-icon>Agregar
                                </v-btn>
                            </div>
                        </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td><div>{{item.nombre}}</div></td>
                                <td><div>{{item.cliente.nombre}}</div></td>
                                <td><div>{{item.descripcion}}</div></td>
                                <td>
                                    <div class="tblOpciones">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" class="botonHover" icon fab dark small @click="abrirModal('update', item)">
                                            <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                        </v-btn>
                                        </template>
                                        <span>Editar</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminar(item)">
                                            <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                        </v-btn>
                                        </template>
                                        <span>Eliminar</span>
                                    </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>

            <template class="borde-card">
                <v-dialog v-model="dialog" persistent max-width="600px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <ValidationObserver ref="form" v-slot="{ invalid }">
                            <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <img :src="imagen" class="imgModal" />
                                    <h2 class="titleModal">{{ tituloModal }}</h2>
                                </div>
                            </v-card-title>

                            <v-card-text class="card_texto">
                                <div id="padre" v-show="isLoadingModal">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!isLoadingModal" grid-list-md id="contenedor">
                                    <v-divider class="divider"></v-divider>
                                    <v-row>
                                        <template v-if="rol == 'root'">
                                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Cliente"
                                                        :items="clientes"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="cliente_value"
                                                        required 
                                                        :error-messages="errors" 
                                                    >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Nombre" rules="required">
                                                    <v-text-field 
                                                        outlined
                                                        label="Nombre" 
                                                        v-model="grupo.nombre" 
                                                        :error-messages="errors" 
                                                        required
                                                    >
                                                    </v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                        </template>
                                        <template v-if="rol=='admin'">
                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Nombre" rules="required">
                                                    <v-text-field 
                                                        outlined
                                                        label="Nombre" 
                                                        v-model="grupo.nombre" 
                                                        :error-messages="errors" 
                                                        required
                                                    >
                                                    </v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                        </template>
                                        
                                        <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                            <ValidationProvider v-slot="{ errors }" name="Descripcion" rules="required">
                                                <v-text-field 
                                                    outlined
                                                    label="Descripción"
                                                    v-model="grupo.descripcion" 
                                                    :error-messages="errors" 
                                                    required
                                                >
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <template>
                                            <v-col xs="12" sm="12" md="12" class="py-0">
                                                <v-select
                                                    v-model="modelo_empresas"
                                                    :items="items_empresas"
                                                    outlined
                                                    item-text="nombre"
                                                    item-value="id"
                                                    label="Empresas Nom"
                                                    multiple
                                                    @change="cambio_emp()"
                                                >
                                                <template v-slot:selection="{ item, index }">
                                                    <v-chip v-if="index === 0">
                                                        <span>
                                                            {{
                                                                item.nombre < 9
                                                                ? item.nombre
                                                                : item.nombre.substr(0, 8) + "..."
                                                            }}
                                                        </span>
                                                    </v-chip>
                                                    <span
                                                        v-if="index === 1"
                                                        class="grey--text caption"
                                                    >(+{{ modelo_empresas.length - 1 }} otros)
                                                    </span>
                                                </template>
                                                </v-select>
                                            </v-col>
                                        </template>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button
                                    class="btnCerrar"
                                    @click="cerrarModal()" 
                                    :disabled="isSaving"
                                >
                                    Cancelar
                                </button>
                                <v-btn
                                    class="btnGuardar"
                                    @click="guardar()" 
                                    :disabled="invalid" 
                                    :loading="isSaving"
                                >
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>

        </v-container>
    </div>
</template>

<script>
import Datatable from '@/components/g-datatable/Datatable.vue';
import queries from '@/queries/Grupos';
import clienteAPi from '@/api/clientes';
import empresaApi from '@/api/empresas';
import Notify from '@/plugins/notify';
import gruposApi from '@/api/grupos';


export default {
    components: {
        'data-table': Datatable,
    },
    data() {
        return {
            columns         : [
                {
                    label: 'Nombre',
                    name: 'nombre',
                    filterable: true
                },
                {
                    label: 'Cliente',
                    name: 'cliente',
                    filterable: false
                },
                {
                    label: 'Descripcion',
                    name: 'dedscripcion',
                    filterable: false
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            filters         : {},
            tableData       : {},
            isLoading       : true,
            paginationData  : {
                numberItems : 10,
                numberPage  : 1
            },
            grupo          : {
                id                      : null,
                cliente_id              : null,
                nombre                  : null,
                descripcion             : null,
                grupos_empresas       : []
            },
            grupos_empresas_eliminadas: [],
            accion              : null,
            tituloModal         : null,
            imagen              : "",
            dialog              : false,
            isSaving            : false,
            clientes            : [],
            empresas            : [],
            sucursales          : [],
            cliente_value       : null,
            empresa_value       : null,
            sucursal_id         : null,
            datosLogin          : null,
            rol                 : null,
            grupo_empresa_id    : null,
            grupo_porcentaje    : null,
            formatGrupoEmp      : [],
            items_empresas      : [],
            modelo_empresas     : [],
            empresas_id         : false,
            isLoadingModal      : false,

        }
    },
    watch: {
        cliente_value: function(val){
            this.cambiaCliente(val);
        },
    },
    methods: {
        getPaginationInfo(data) {
            switch(data.type) {
                case "page":
                    this.paginationData.numberPage = data.value
                    break;

                case "itemsToShow":
                    this.paginationData.numberItems = data.value
                    break;
            }
            this.isLoading = true;
        },
        async abrirModal(accion, data = null){
            this.resetValues();
            let self = this;
            this.accion     = accion;
            this.isLoadingModal  = true;

            this.getClientes();
            if(accion == "add"){
                this.tituloModal    = "Registrar grupo";
                this.imagen         = "/static/modal/gruposCreate.svg";
                this.isLoadingModal      = false;
            } else {
                this.tituloModal            = "Actualizar grupo";
                this.imagen                 = "/static/modal/gruposUpdate.svg";
                this.grupo.id               = data.id;
                this.grupo.cliente_id       = data.cliente_id;
                this.cliente_value          = data.cliente_id;
                this.grupo.nombre           = data.nombre;
                this.grupo.descripcion      = data.descripcion;
                this.formatGrupoEmp         = data.grupoEmpresas;
                
                let emp = [];
                this.formatGrupoEmp.forEach((element) => {
                    emp.push({id: element["empresa_id"]});
                });
                this.modelo_empresas = emp;

                this.isLoadingModal  = false;

            }

            if (this.rol == "root") {
                this.getClientes();
            }

            if (this.rol == "admin") {
                //this.cambiaCliente()
                /* this.cambiaCliente(
                    this.datosLogin.cliente_id,
                    this.usuario.empresa_id
                ); */
                this.cliente_value = this.datosLogin.cliente_id;
            }
            setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
        },
        cerrarModal() {
            this.dialog     = false;
            this.isSaving   = false;
            this.resetValues();
        },
        resetValues() {
            this.grupo      = {
                id                  : null,
                cliente_id          : null,
                nombre              : null,
                descripcion         : null,
                grupos_empresas     : []
            }
            this.cliente_value      = null;
            this.empresa_value      = null;
            this.clientes           = [];
            this.empresas           = [];
            this.sucursales         = [];
            this.modelo_empresas    = [];

            this.$nextTick(() => {
                this.$refs.form.reset();
            });
        },
        eliminar(data){
            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas eliminar el grupo " + data.nombre + "?",
                "Eliminar",
                () => {
                    this.isLoading = true;
                    gruposApi.delete(data.id).then(response => {
                        Notify.Success("Grupo eliminado", "El grupo ha sido eliminado satisfactoriamente");
                        this.$apollo.queries.grupos.refetch();
                    })
                    .catch(err => {
                        this.isLoading = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            )
        },
        getClientes(cliente = null) {
            let param = { activo: true, paginate: false }
            clienteAPi.getClientes(param).then((response) => {
                this.clientes = response.data;
            })
            .catch(error => {
                this.resetValues();
                Notify.ErrorToast(error.response.data.error);
            });
        },
        async cambiaCliente(id = null, cliente = null) {
            if(this.grupo.cliente_id != id){
                this.modelo_empresas = [];
            }
            this.modelo_empresas
            let paramEmp = {activo: true, paginate: false, cliente_id:id};
            await empresaApi.getEmpresas(paramEmp).then((response)=>{
                this.items_empresas = response.data; 
            })
            .catch(error => {
                this.resetValues();
                Notify.ErrorToast(error.response.data.error);
            });
        },
        guardar() {
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }
                this.grupo.cliente_id      = this.cliente_value;

                let emp = [];
                if(this.accion == "add"){
                    this.modelo_empresas.forEach((element) => {
                        emp.push({
                            empresa_id:element,
                        })
                    })
                } else if(this.accion == "update"){
                    if(this.empresas_id){
                        this.modelo_empresas.forEach((element) => {
                            emp.push({
                                empresa_id: element,
                            });
                        });
                    }else{
                        this.modelo_empresas.forEach((element) => {
                            emp.push({
                                empresa_id: element["empresa_id"],
                            });
                        });
                    }
                }
                this.grupo.grupos_empresas = emp;

                this.isLoading = true;
                if(this.accion === "add") {
                    gruposApi.store(this.grupo)
                    .then((response) => {
                        Notify.Success("Excelente!", "El grupo se guardó satisfactoriamente.");
                        this.$apollo.queries.grupos.refetch();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isLoading = false;
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
                else if(this.accion === "update") {
                    gruposApi.update(this.grupo)
                    .then((response) => {
                        Notify.Success("Excelente!", "El grupo se actualizó satisfactoriamente.");
                        this.$apollo.queries.grupos.refetch();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isLoading = false;
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            });
        },
        cambio_emp(){
            this.empresas_id = true;
        },
    },
    apollo: {
        grupos: {
            query       : queries.gruposTableQuery,
            fetchPolicy : 'network-only',
            variables() {
                this.isLoading =  true;
                return {
                    numberItems : this.paginationData.numberItems,
                    numberPage  : this.paginationData.numberPage,
                    fieldOrder  : this.$refs.tabla.sortKey ? this.$refs.tabla.sortKey : 'nombre',
                    Order       : this.$refs.tabla.Order,
                }
            },
            skip() {
                return true
            },
            result({loading, error}) {
                this.isLoading  = false;
                this.loading    = false;
                this.tableData  = this.grupos;
            },
        }
    },
    created() {
        this.datosLogin = this.$session.get("usuario");
        this.rol = this.datosLogin.rol.name;
    },
    mounted(){
        this.$apollo.queries.grupos.skip = false;
    },
}
</script>

<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }
</style>
